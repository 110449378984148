.Button {
    background-color: transparent;
    border: 1px solid gold;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 1rem;
    margin: 0px;
    font-weight: bold;
}

.Success {
    color: gold;
}

.Danger {
    color: red;
}