.GameConfig {
    padding: 1rem;
}

.GameConfig .field {
    border: none;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
}

.GameConfig table {
    width: 100%;
}

.GameConfig table td.Label{
    width: 2em;
    vertical-align: top;
}

.GameConfig table td.Slider {
    vertical-align: top;
}

.GameConfig .field label {
    text-transform: capitalize;
    margin-bottom: 0.5rem;
    display: inline-block;
    text-align: center;
    font-size: 1.5rem;
}

.GameConfig .actions {
    text-align: center;
}

.GameConfig .actions button {

}