.Board {
    display: table;
    width: auto;
    margin: auto;
    background-color: #eee;
    border-spacing: 0; /* cellspacing:poor IE support for  this */
    border: 5px solid transparent;
}

.Completed {
    border: 5px solid green;
}

.Failed {
    border: 5px solid red;
}

.Board > .Row {
    display: flex;
    width: auto;
    clear: both;
    margin: 0;
    border: 0;
}

.Board > .Row > .Column {
    float: left; /* fix for  buggy browsers */
    width: auto;
    background-color: #ccc;
    border: 0;
    margin: 0;
}