.Tile {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    vertical-align: center;

    -moz-user-select: none;
    -webkit-user-select: none;

}

.Closed {
    background: #c0c0c0;

    border: 4px solid black;
    border-top-color: #fff;
    border-left-color: #fff;
    border-right-color: #808080;
    border-bottom-color: #808080;

    cursor: pointer
}

.Opened {
    background: #dddddd;
    border: 4px solid black;
    border-bottom-color: #fff;
    border-right-color: #fff;
    border-left-color: #808080;
    border-top-color: #808080;

    cursor: default;
}

.fa-flag {
    color: red;
}