.Scoreboard {
    display: inline-block;
    width: auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: center;
}

.Remaining {
    display: inline-block;
    padding: 5px;
}

.Elapsed {
    display: inline-block;
    padding: 5px
}

.Reset {
    display: inline-block;
    padding: 5px;
}

.Reset .ResetButton {
    background-color: transparent;
    border: transparent;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
}

.fa-smile {
    color: gold;
}

