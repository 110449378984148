.InfoVersion {
    font-size: 0.5rem;

    position: fixed;
    right:0;
    bottom:0;

    padding: 3px;
}

.InfoCopyright {
    font-size: 0.5rem;

    position: fixed;
    left:0;
    bottom:0;

    padding: 3px;
}

